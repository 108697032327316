<template>
    <div>
        <v-container>
            <v-card style="background-color: #F5F5F5" elevation="3">
                <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                    <span v-if="isNew">Nuevo</span>
                    <span v-if="!isNew">Editar</span>
                    Cuestionario
                </div>

                <v-form ref="form" v-model="isValid" autocomplete="off">
                    <v-tabs v-model="tab" background-color="transparent" show-arrows>
                        <v-tabs-slider color="blue"></v-tabs-slider>

                        <v-tab href="#tab-cuestionario">Cuestionario</v-tab>
                        <v-tab href="#tab-cat">Categorías</v-tab>
                        <v-tab href="#tab-emp">Empresas</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab" touchless>
                        <v-tab-item :value="'tab-cuestionario'">
                            <v-card style="background-color: #F5F5F5" flat>
                                <!-- FORMULARIO DIRECCIÓN -->
                                <v-row>
                                    <v-col class="px-7 mt-2" cols="12" sm="9" md="6" lg="6" xl="5">
                                        <v-text-field class="mb-n7" outlined dense text v-model="Cuestionario.Titulo" required
                                            :rules="[v => !!v || 'El nombre es obligatorio']" label="Nombre *">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="px-7 mt-2" cols="12" sm="9" md="6" lg="6" xl="5">
                                        <v-textarea class="mt-0 mb-n7" outlined auto-grow v-model="Cuestionario.Descripcion" required
                                            :rules="[v => !!v || 'La descripción es obligatoria']" label="Descripción *">
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="px-7 mt-2" cols="12" sm="8" md="6" lg="6" xl="4">
                                        <v-checkbox
                                            class="mt-n3 mb-n3"
                                            v-model="Cuestionario.Visible"
                                            label="Visible">
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                    <!-- ACEPTAR / CANCELAR / BORRAR -->
              <v-row class="mt-2" justify="center">
                <v-btn
                    :loading="loading"
                    :disabled="!isValid"
                    @click="onBtnSave"
                    color="primary"
                    class="mt-2 mb-4 mr-2"
                    style="width:100px">
                    Aceptar
                </v-btn>
                
                <v-btn
                    class="mt-2 mb-4 mr-2"
                    style="width:100px"
                    @click="goToList">
                    Cancelar
                </v-btn>

                <!-- && canDelete" -->
                <v-btn v-if="!isNew"
                  color="error"
                  class="mt-2 mb-4"
                  style="width:100px"
                  @click="onBtnShowDeleteModal()">
                    Borrar
                </v-btn>
            </v-row>
                </v-form>
            </v-card>
        </v-container>
    </div>
</template>
<script>
import axios from "axios";
import { mapState } from "vuex";
//import ElemCatFind from "../components/ElemCatFind.vue";
//import EmpresasRelacionadas from "../components/EmpresasRelacionadas.vue";
export default ({
    //components: {
    //    ElemCatFind, EmpresasRelacionadas
    //},
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            tab: null,
            loading: false,
            isNew: true,
            isValid: false,
            showDeleteDialog: false,
            showCuestionarioCatDeleteDialog: false,
            Cuestionario: {
                CuestionarioId: 0,            
                Titulo: '',
                Descripcion:'',
                Visible: false
             
            },
           
        }
    },
    mounted() {
      this.isNew = this.$route.meta.isNew;
      
      if(!this.isNew) {
        this.Cuestionario.CuestionarioId = this.$route.params.id;
        this.loadData();
      } else {
        // Usar eager en la v-tab-item para poder tener la ref cargada
        this.$refs.relatedEmps.loadDefaultEmps();
      }
    },
    methods: {
      goToList() {
        this.$router.push('/cuestionarios');
      },
      getDataFromApi() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            CuestionarioId: this.Cuestionario.CuestionarioId
          };

          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/cuestionario/getCuestionario", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar el cuestionario');
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
             Cuestionario: this.Cuestionario
          };

          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/cuestionario/new", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al guardar el cuestionario2');
            }
          });
        })
      },
      //#region Click guardado
      onBtnSave() {
          this.validate();
          if(!this.isValid) { return ; }
          this.postDataToApi().then(data => {
            console.log(data)
            if(data.item != null) {
              alert('Cuestionario guardado correctamente')
              this.goToList();
            }
            else {
              alert('Error al guardar el cuestionario2.');
            }
          });
      },
      //#region Validar formulario
      validate() {
        this.$refs.form.validate();
      }
    }
})
</script>